export const checkBrowserPermissions = async (cb) => {
  try {
    const cameraPermission = await navigator.permissions.query({ name: 'camera' });
    const microphonePermission = await navigator.permissions.query({ name: 'microphone' });

    if (cameraPermission.state === 'denied' || microphonePermission.state === 'denied') {
      cb();
    } else if (cameraPermission.state === 'prompt' || microphonePermission.state === 'prompt') {
      cb();
    } else {
      console.log('Permissions are already granted.');
    }

  } catch (error) {
    console.error('Error checking permissions:', error);
  }
}